import React from 'react'
import Alerta from '../../../../src/common/assets/home/icon_home_alerta.svg'
import Calendario from '../../../../src/common/assets/home/icon_home_calendario.svg'
import Confianza from '../../../../src/common/assets/home/icon_home_confianza.svg'
import Neumatico from '../../../../src/common/assets/home/icon_home_neumatico.svg'
import Taller from '../../../../src/common/assets/home/icon_home_taller.svg'

const valoresText = {
  neumatico_es: (
    <p>
      Servicios de <strong>neumáticos</strong> y <strong>mantenimiento</strong>{' '}
      al mejor precio
    </p>
  ),
  neumatico_ca: (
    <p>
      Serveis de <strong>pneumàtics</strong> i <strong>manteniment</strong> al
      millor preu
    </p>
  ),
  calendario_es: (
    <p>
      <strong>Cita previa online,</strong> escoge fecha y hora
    </p>
  ),
  calendario_ca: (
    <p>
      <strong>Cita prèvia online,</strong> tria data i hora
    </p>
  ),
  taller_es: (
    <p>
      Amplia red de <strong>160 talleres propios</strong>
    </p>
  ),
  taller_ca: (
    <p>
      Amplia xarxa de <strong>160 tallers propis </strong>
    </p>
  ),
  alerta_es: (
    <p>
      Cuidado y mantenimiento de tu vehículo con{' '}
      <strong>alertas personalizadas</strong>
    </p>
  ),
  alerta_ca: (
    <p>
      Cura i manteniment del teu vehicle amb{' '}
      <strong>alertes personalitzades</strong>
    </p>
  ),
  confianza_es: (
    <p>
      Atención personalizada por profesionales de <strong>confianza</strong>
    </p>
  ),
  confianza_ca: (
    <p>
      Atenció personalitzada per professionals de <strong>confiança</strong>
    </p>
  ),
}

const valores = [
  {
    icon: <Neumatico />,
    text_es: valoresText.neumatico_es,
    text_ca: valoresText.neumatico_ca,
    link: 'neumaticos',
  },
  {
    icon: <Calendario />,
    text_es: valoresText.calendario_es,
    text_ca: valoresText.calendario_ca,
    link: 'cita-previa.seleccion',
  },
  {
    icon: <Taller />,
    text_es: valoresText.taller_es,
    text_ca: valoresText.taller_ca,
    link: 'talleres',
  },
  {
    icon: <Alerta />,
    text_es: valoresText.alerta_es,
    text_ca: valoresText.alerta_ca,
    link: 'register',
  },
  {
    icon: <Confianza />,
    text_es: valoresText.confianza_es,
    text_ca: valoresText.confianza_ca,
    link: 'contacto',
  },
]

export default valores
