import React from 'react'
import Stars from '../../../../src/common/components/stars/Stars'
import HomeSlider from '../../../../src/common/components/home/HomeSlider'
import styles from './Opiniones.module.scss'
import { FormattedMessage } from 'react-intl'

interface Props {
  className: string
}

const OpinionesInner = ({ className }: Props) => {
  return (
    <React.Fragment>
      <div
        key={1}
        style={{ zIndex: 4 }}
        className={`${className} ${styles.opinion}`}>
        <Stars starsFilled={5} />
        <p>
          <FormattedMessage id="opinion-text.texto1" />
        </p>
        <div className={styles.autor}>Carlos G.</div>
      </div>

      <div
        key={2}
        style={{ zIndex: 3 }}
        className={`${className} ${styles.opinion}`}>
        <Stars starsFilled={5} />
        <p>
          <FormattedMessage id="opinion-text.texto2" />
        </p>
        <div className={styles.autor}>Laura R.</div>
      </div>

      <div
        key={3}
        style={{ zIndex: 2 }}
        className={`${className} ${styles.opinion}`}>
        <Stars starsFilled={5} />
        <p>
          <FormattedMessage id="opinion-text.texto3" />
        </p>
        <div className={styles.autor}>Sergio C.</div>
      </div>

      <div
        key={4}
        style={{ zIndex: 1 }}
        className={`${className} ${styles.opinion}`}>
        <Stars starsFilled={5} />
        <p>
          <FormattedMessage id="opinion-text.texto4" />
        </p>
        <div className={styles.autor}>Daniel S.</div>
      </div>
    </React.Fragment>
  )
}

const Opiniones = () => (
  <section className={styles.opiniones}>
    <h2>
      <FormattedMessage id="home.dicen_clientes" />
    </h2>
    <HomeSlider>
      {({ className }) => <OpinionesInner className={className} />}
    </HomeSlider>
  </section>
)

export default Opiniones
