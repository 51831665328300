import React, { useEffect, useRef, useState } from 'react'
import NeumaticoHome from '../../assets/home/neumatico_home.svg'
import MotoIcon from '../../assets/images/family_icons/icon_moto.svg'
import RevisionIcon from '../../assets/menu/icon_revision.svg'
import MotoRevisionIcon from '../../assets/menu/icon_revision_moto.svg'
import BateriaIcon from '../../assets/menu/icon_battery.svg'
import SelectorContainer from '../FunnelSelector/Entrada/SelectorContainer'
import SelectorHome from '../FunnelSelector/Entrada/SelectorHome'
import RevisionHome from '../revisionHome'
import styles from './index.module.scss'
import route from '../../utils/route'
import dataLayer from '../../helpers/functions/dataLayer'
import { t } from '../../i18n'
import { navigate } from 'gatsby'
import { FEATURE_FLAGS, isFeatureEnabled } from '../../utils/featureFlag'

export default function TabsHome() {
  const [tabActive, setTabActive] = useState(0)
  const isInitialMount = useRef(true)

  const tabsArray = [
    {
      id: 0,
      icon: <NeumaticoHome className={styles.icon_neumatico} />,
      title: 'home.encontrar_neumaticos',
      link: '',
    },
    {
      id: 1,
      icon: <RevisionIcon className={styles.icon} />,
      title: 'home.revision_cambio_aceite',
      link: '',
    },
  ]

  if (isFeatureEnabled(FEATURE_FLAGS.NEUMATICOS_MOTO)) {
    tabsArray.push({
      id: 2,
      icon: <MotoIcon className={styles.icon_neumatico} />,
      title: 'menuHeader.neumaticos_moto',
      link: route('landing-moto'),
    })
  }
  if (isFeatureEnabled(FEATURE_FLAGS.REVISION_MOTO)) {
    tabsArray.push({
      id: 3,
      icon: <MotoRevisionIcon className={styles.icon_neumatico} />,
      title: 'home.revision_moto',
      link: route('moto.revisiones.index'),
    })
  }
  /* if (isFeatureEnabled(FEATURE_FLAGS.BATERIA)) {
    tabsArray.push({
      id: 3,
      icon: <BateriaIcon className={styles.icon_battery} />,
      title: 'home.baterias',
      link: route('baterias.index'),
    })
  } */

  useEffect(() => {
    // No informamos en el primer render, puesto que no es que el usuario haya cambiado
    if (isInitialMount.current) {
      isInitialMount.current = false
      return
    }

    dataLayer.push({
      event: 'tab',
      action: 'click',
      label: tabActive === 0 ? 'neumaticos' : 'revision',
    })
  }, [tabActive])

  return (
    <div className={styles.tabs_home_wrapper}>
      <ul className={styles.tabrow}>
        {tabsArray.map((tab) => {
          return tab.link ? (
            <li
              key={tab.id}
              onClick={() => {
                navigate(tab.link)
              }}
              className={styles.tab}>
              <a href={tab.link} className={styles.tab_title}>
                {tab.icon}
                {t(tab.title)}
              </a>
            </li>
          ) : (
            <li
              key={tab.id}
              onClick={() => {
                setTabActive(tab.id)
              }}
              className={
                tabActive === tab.id
                  ? `${styles.tab} ${styles.selected}`
                  : styles.tab
              }>
              <div className={styles.tab_title}>
                {tab.icon}
                {t(tab.title)}
              </div>
            </li>
          )
        })}
      </ul>
      <div className={styles.content}>
        {tabActive === 0 && (
          <SelectorContainer showMarcas={false} origin={route('index')}>
            {({ ...props }) => <SelectorHome {...props} />}
          </SelectorContainer>
        )}
        {tabActive === 1 && <RevisionHome />}
      </div>
    </div>
  )
}
