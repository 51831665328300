import { navigate } from 'gatsby'
import React from 'react'
import Battery from '../../assets/menu/icon_battery.svg'
import { t } from '../../i18n'
import ImageBaterias from '../../../specific/images/home/baterias-coche-rodi.png'
import route from '../../utils/route'
import CardHome from '../cardHome/CardHome'
import styles from './index.module.scss'

export default function BateriasCardMobileHome() {
  return (
    <CardHome
      icon={<Battery className={styles.battery_icon} />}
      title={t('home.baterias')}
      textButton={t('home.elegir')}
      onClickButton={() => navigate(route('baterias.index'))}>
      <div className={styles.image_wrapper}>
        <img
          width="270"
          height="80"
          src={ImageBaterias}
          alt={t('home.baterias')}
        />
      </div>
    </CardHome>
  )
}
